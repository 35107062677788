<template>
    <div>

    </div>
</template>

<script>
    export default {
        name:"purchaseInvoices",
        data() {
            return {
                key: ""
            }
        },
    }
</script>

<style scoped>

</style>